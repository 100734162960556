// Styles specific to the Page Header

.header {
  //grid-area: area-header;
  flex-shrink: 0;

  display: grid;
  grid-template-areas:
    "logo search-panel sys-tray"
    "breadcrumb breadcrumb breadcrumb";
  grid-template-columns: 20rem auto 20rem;
  grid-template-rows: auto auto;

  //background-color: linear-gradient(90deg, $color-grey-mid, $color-grey-dark); // $color-bg-form;
  @include gradient($color-grey-mid-dark, $color-grey-mid);

  min-height: 4rem; // VARIES IN HEIGHT

  .header__logo {
    grid-area: logo;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 6rem;

    .logo__img {
      left: 0;
      @include respond(tab-land) {
        width: 20rem;
      }
      @include respond(tab-port) {
        width: 15rem;
      }
      @include respond(phone) {
        display: none;
      }

      max-width: 70%;
    }
  }

  .header__search-panel {
    grid-area: search-panel;

    display: flex;
    //align-items: stretch;
    //justify-content: center;

    margin-right: 0.5rem;

    margin: 0.8rem 0;

    .header__simple-search-filters {
      margin: 0.5rem 0;
    }
  }

  .header__create-record {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header__create-record-dropdown {
    position: relative;
  }

  .simple-search__actions,
  .header__create-record-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $color-bg-button;
    color: $color-white;

    width: 2.5rem;
    height: 100%;

    transition: all 0.2s;

    border-radius: 0 0.5rem 0.5rem 0;

    &:hover {
      cursor: pointer;
      background-color: $color-bg-button-highlight;
    }

    &--popup-visible {
      border-radius: 0 0.5rem 0 0;
    }

    .search-actions-dropdown__popup,
    .create-record-dropdown__popup {
      position: absolute;

      background-color: $color-bg-button;

      border-radius: 0.5rem 0 0.5rem 0.5rem;
      @include popup-shadow;

      width: 15rem;

      top: 4rem;
      right: 0;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;

      padding: 0.5rem 0;

      z-index: 10;

      p,
      a {
        padding: 0.2rem 0.5rem;

        //font-size: $font-size-small;
        font-weight: normal;
        color: $color-white;
        text-decoration: none;

        &:hover {
          background-color: $color-bg-button-highlight;
        }
      }
    }
  }

  .header__create-record-dropdown {
    grid-area: create-record;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 12rem;

    border-radius: 0.5rem;

    &--popup-visible {
      border-radius: 0.5rem 0.5rem 0 0;
    }

    .create-record-dropdown__popup {
      width: 15rem;
    }
  }

  .header__sys-tray {
    grid-area: sys-tray;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin-left: 5rem;

    z-index: 99;

    .header__user-dropdown,
    .header__messages-dropdown,
    .header__settings-dropdown {
      position: relative;

      width: 2.5rem;
      height: 2.5rem;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.2s;

      &:hover {
        cursor: pointer;
        //transform: scale(1.1);
      }
    }

    .user-dropdown__popup,
    .messages-dropdown__popup,
    .settings-dropdown__popup,
    .language-dropdown__popup {
      position: absolute;

      background-color: $color-grey-lightest;

      border-radius: 0.5rem 0 0.5rem 0.5rem;

      width: 11rem;

      top: 2.7rem;
      right: 0;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;

      padding: 0.5rem 0;

      p {
        font-size: $font-size-small;
        padding: 0.2rem 0.5rem;

        &:hover {
          background-color: $color-grey-lighter;
        }
      }
    }

    .language-dropdown__popup {
      top: 5rem;
      left: 2.5rem;

      width: 10rem;

      border-radius: 0 0.5rem 0.5rem 0.5rem;
    }
  }

  .breadcrumb-container {
    grid-area: breadcrumb;
    background-color: $color-grey-light;
    padding: 2px 1rem;

    .breadcrumbs {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      li {
        display: flex;
        align-items: center;
        justify-content: center;

        //margin-right: 8px;
        position: relative; /* Needed for positioning the pseudo-element */

        list-style: none;
        display: flex;
        padding: 0; /* Remove default padding */
        margin: 0 0.5rem;

        text-decoration: underline;
        color: $color-text-primary;

        &:not(:first-of-type)::before {
          content: ">";
          margin-right: 8px;
          position: absolute;
          left: -10px; /* Adjust this value as needed */
        }

        &:hover {
          cursor: pointer;
          color: $color-text-primary-highlight;
        }

        &:last-of-type {
          text-decoration: none;
          color: $color-black;

          &:hover {
            cursor: default;
          }
        }
      }
    }
  }
}
