.add-connected {
  @include full-screen-popup;

  .add-connected__search-container {
    //position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    margin: 0.5rem;
    //padding: 0.5rem 1rem;

    .simple-search__input-container {
      button {
        height: 100%;
        min-height: 4rem;
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }

    .add-connected__header {
      padding: 0.5rem;
      background-color: $color-bg-primary;
      color: $color-white;
      width: 100%;
    }

    .add-connected__type-selector {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      .add-connected__type-selector-name {
        //font-weight: bold;
        padding: 0.5rem;
      }

      .add-connected__type-selector-value {
        background-color: $color-white;
        padding: 0.25rem 0.5rem 0.75rem 0.5rem;
        min-width: 15rem;
        border-radius: 0.5rem;

        &--disabled {
          background-color: transparent;
        }
      }
    }

    .add-connected__search-panel {
      margin-left: 0.5rem;
    }

    .add-connected__actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      flex-grow: 1;

      min-height: 4rem;

      button {
        //height: 100%;
        margin-right: 0.5rem;
      }
    }

    .add-connected__search-results {
    }
  }
}
