//generic modal container and body

.modal {
  &__selection-popup {
    @include popup-shadow;
    width: 35rem;
    background-color: $color-grey-light;

    border-radius: 0.5rem;

    color: $color-grey-dark;

    .popup-header {
      border-radius: 0.5rem 0.5rem 0 0;
      padding: 1rem;
    }
    &--wide {
      width: 50rem;
    }
  }

  &__selection-body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    p {
      font-weight: bold;
    }
  }

  &__selection-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding: 1rem;
    font-size: $font-size-small;

    button {
      margin: 0 1rem;
    }

    &--success {
      color: $color-green-dark;
      font-weight: bold;
      font-size: $font-size-large;
    }

    &--left {
      justify-content: flex-start;
    }
  }

  &__selection-grid {
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    grid-row: auto auto;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    padding: 1rem 0 1rem 0;

    &--50 {
      width: 50%;
    }
    &--75 {
      width: 75%;
    }

    &--100 {
      width: 100%;
    }
  }

  &__label {
    font-size: $font-size-small;
    font-weight: bold;
  }

  &__select {
    width: 100%;
    background-color: $color-white;
    padding-left: 1rem;
    border-radius: 0.5rem;
    &--auto-width {
      width: auto;
    }
  }
  &__input {
    width: 100%;
    padding-left: 1rem;
    border-radius: 0.5rem;
  }
  &__warning-text {
    text-align: center;
  }
}
