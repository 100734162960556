.tabs {
  .tabs__header {
    display: flex;
  }

  .tabs__header__label {
    padding: 1rem;
    font-size: large;
    border-radius: 1rem 1rem 0 0;
    background-color: $color-grey-lighter;

    cursor: pointer;

    &:hover {
      background-color: $color-grey-light;
    }

    &--focused {
      background-color: $color-blue-mid;
      color: white;
      font-weight: bold;

      &:hover {
        background-color: $color-blue-light;
      }
    }
  }

  .tabs__content {
    min-width: 40rem;
    border: 2px solid $color-bg-primary;
    padding: 1rem;
    border-radius: 0 1rem 1rem 1rem;

    background-color: $color-white;
  }
}
