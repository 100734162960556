.flex-column-center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  h1,
  h2,
  h3,
  h4,
  p {
    padding: 0.5rem;
  }
}

.flex-row-center,
.flex-row-start,
.flex-row-end,
.flex-row-space-between,
.flex-row-space-around,
.flex-row-space-evenly {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .item {
    &:not(:last-of-type) {
      margin: 0.5rem 0;
    }
  }
}

.flex-row-center {
  justify-content: center;
  gap: 0.5rem;
}

.flex-row-start {
  justify-content: flex-start;
}

.flex-row-end {
  justify-content: flex-end;
}

.flex-row-space-between {
  justify-content: space-between;
}

.flex-row-space-around {
  justify-content: space-around;
}

.flex-row-space-evenly {
  justify-content: space-evenly;
}

.margin-0-5-rem {
  margin: 0.5rem;
}

.margin-1-rem {
  margin: 1rem;
}

.padding-0-5-rem {
  padding: 0.5rem;
}

.padding-1-rem {
  padding: 1rem;
}

.margin-0-5-rem-top {
  margin-top: 0.5rem;
}

.margin-1-rem-top {
  margin-top: 1rem;
}

.padding-0-5-rem-top {
  padding-top: 0.5rem;
}

.padding-1-rem-top {
  padding-top: 1rem;
}

.margin-0-5-rem-bottom {
  margin-bottom: 0.5rem;
}

.margin-1-rem-bottom {
  margin-bottom: 1rem;
}

.padding-0-5-rem-bottom {
  padding-bottom: 0.5rem;
}

.padding-1-rem-bottom {
  padding-bottom: 1rem;
}

.sort-spacer {
  display: inline-block;
  width: 1rem;
}
