.saved-searches {
  position: absolute;
  top: 3.8rem; // calc(50% - 15rem);
  left: -2px;
  width: calc(100% + 4px);
  z-index: 900;

  overflow-y: auto;

  background-color: $color-bg-primary;
  @include popup-shadow;

  border-radius: 0 0 1rem 1rem;
  padding: 0.5rem;
  //margin: -0.5rem;

  .saved-searches__header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;

    background-color: $color-grey-light;

    .header__title {
      color: $color-grey-darker;
      font-size: $font-size-large;
      font-weight: bold;
      padding: 0.5rem;
    }

    .header__search-panel {
      padding: 0 0.5rem;

      input {
        border-radius: 0.5rem;
        margin-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  .saved-searches__body {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    padding-top: 0.5rem;

    max-height: calc(100vh - 10rem);
    min-height: 0;

    overflow-y: auto;

    border-radius: 0 0 0.5rem 0.5rem;
    background-color: $color-white;

    .saved-searches__response-message {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      font-weight: bold;
      color: $color-green-dark;
      &--error {
        color: $color-red-dark;
      }
    }

    .saved-searches__row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      //height: 110rem;

      padding: 0.5rem 1rem;

      color: $color-bg-primary;

      .saved-searches__cell {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        font-weight: bold;
        text-decoration: underline;

        &--15 {
          width: 15%;
        }

        &--30 {
          width: 30%;
        }

        &--70 {
          width: 70%;
        }
      }
    }
  }
}

.search-actions-dropdown__add-saved-search-popup {
  position: absolute;
  left: 2.5rem;
  top: 8rem;

  z-index: 999;

  width: 20rem;
  border-radius: 0 0.5rem 0.5rem 0.5rem;

  background-color: $color-grey-light;

  .popup-header {
    border-radius: 0 0.5rem 0 0;
  }

  .add-saved-search-popup__body {
    padding: 1rem;

    .add-saved-search-popup__row {
      margin-bottom: 0.5rem;

      .form-label {
        font-size: $font-size-small;
        font-weight: bold;
      }

      color: $color-text-primary;
    }
  }
}
