.content__report-menu {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  width: calc(100% - 3rem);
  margin-right: 3rem;
  background-color: $color-white;

  .report-menu__title {
    font-size: $font-size-x-large;
    font-weight: bold;
    color: $color-text-primary;
    margin: 1rem 0 0 1rem;
  }
}

.basic-report__content {
  width: 100%;
  background-color: $color-grey-lighter;
  border-radius: 1rem;
  border: 1px solid $color-bg-primary;
  max-height: calc(100vh - 3rem);
  overflow-y: scroll;
  //color: $color-grey-dark;
  @include popup-shadow;

  padding: 1rem;

  .report-menu__section-header {
    margin: 0;
  }
  button {
    &:hover {
      background-color: $color-bg-primary-highlight;
    }
    margin-right: 0.5rem;
  }
}

.basic-report__header-line {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  .basic-report__title {
    font-size: $font-size-x-large;
    font-weight: bold;
    color: $color-text-primary;
    //margin: 1rem 0 0 1rem;
  }

  .basic-report__close-icon {
    font-size: $font-size-x-large;
    &:hover {
      cursor: pointer;
      color: $color-grey-dark;
    }
  }
}

.report-menu__section {
  overflow: auto;
  background-color: $color-grey-lightest;
  border: 1px $color-bg-primary solid;
  border-radius: 0.5rem;

  margin: 1rem;

  // &:not(:last-of-type) {
  //   margin-right: 0;
  // }
  width: 100%;

  min-width: 40%;

  &--33 {
    max-width: 33%;
  }

  .report-menu__report-list {
    width: 100%;
    .report-menu__group {
      .group__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        padding: 0.5rem;

        background-color: $color-bg-primary-highlight;
        color: $color-white;
        &:hover {
          text-decoration: underline;
        }

        cursor: pointer;

        transition: 0.2s all;

        .group__title {
        }
      }
      .group__body {
      }
    }
  }

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding: 0.5rem 1rem;

    &:nth-child(even) {
      background-color: $color-white;
    }

    .item__title {
      width: 50%;
      &--selected {
        font-weight: bold;
      }
    }

    .item__props {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;

      width: 35%;

      font-size: $font-size-x-small;
      .item__prop {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .item__prop-label {
          font-weight: bold;
        }
        .item__prop-value {
          padding-left: 0.5rem;
        }
      }
    }

    .item__toolbar {
      display: inline-block;
      width: 2rem;
      &:hover {
        color: $color-red;
      }
    }
  }
}

.item__actions {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  .actions__item-action {
    margin-left: 0.5rem;
    cursor: pointer;
    min-width: 1rem;

    &--delete {
      min-width: 2rem;
    }

    button a {
      color: $color-white;
      text-decoration: none;
    }
  }
}

.report-menu__section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background-color: $color-bg-primary;
  color: $color-white;
  border-radius: 0.5rem 0.5rem 0 0;

  padding: 0.7rem;

  .header__title {
    font-size: $font-size-large;
    font-weight: bold;
  }

  button {
    background-color: $color-grey-lighter;
    border-radius: 0.5rem;
    padding: 0.5rem;
    color: $color-text-primary;

    &:hover {
      background-color: $color-grey-lightest;
    }
  }
}

.report-menu__section-body {
  background-color: $color-grey-light;
  margin-bottom: 1rem;

  &--field-layout {
    height: 33rem;
  }

  .section-body__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    //margin: 0.5rem;
  }

  .section-body__field {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    border-bottom: $color-bg-primary 1px solid;
    border-radius: 0.5rem 0.5rem 0 0;

    margin: 0.5rem;

    //flex-grow: 1;
    //position: relative;

    min-height: 4rem;
    min-width: 20rem;
    background-color: $color-bg-form;
    color: $color-text-secondary;

    &--transparent {
      justify-content: center;
      align-items: center;
      border-bottom: 0;
      min-width: auto;
      min-height: auto;

      color: $color-text-primary;
      background-color: transparent;
    }

    &--warning {
      //background-color: $color-yellow-lightest;
      color: $color-yellow-dark;
      border-color: $color-yellow-darkest;
    }

    &--error {
      @include error-box;
    }

    .field__display-name {
      //position: absolute;
      margin-left: 0.5rem;
      font-size: $font-size-small;
    }
    .field__display-value {
      font-size: $font-size-medium;
      color: $color-text-primary;
      padding: 0 0.5rem 0 0.5rem;

      width: 100%;
      input,
      textarea {
        width: 100%;
        height: auto;
        padding-left: 0;

        background-color: transparent;
        color: $color-text-primary;

        font-size: $font-size-medium;

        &:focus {
          background-color: $color-white;
          padding-left: 0.5rem;
        }
      }

      .select {
        padding-top: 0;
      }
    }
    .field__error {
      color: $color-red-darker;
      font-size: $font-size-small;
    }
  }
  .report-section__create-button {
    align-items: center;
    color: $color-white;
    background-color: $color-bg-primary;
    padding: 3px 10px;
    &:hover {
      background-color: $color-bg-primary-faded;
    }
  }
}

// Overrides for search results viewer
.search-results__report-viewer {
  .report-menu__section {
    height: 100%;
  }

  .item__title {
    color: $color-text-primary;
  }
}

.quick-report-grid-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;

  .quick-report-grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-auto-rows: auto;
    padding: 0.5rem;

    width: 100%;

    .quick-report-grid__cell {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;

      background-color: $color-grey-lightest;
      border: 1px solid $color-bg-primary;

      padding: 0.5rem;

      min-height: 3rem;

      cursor: grab;

      .cell__title {
        font-size: $font-size-medium;
        font-weight: bold;
        color: $color-text-primary;
      }

      .cell__value {
        font-size: $font-size-large;
        font-weight: bold;
        color: $color-text-primary;
      }
    }
  }
  .quick-report-grid__remove-button {
    background-color: $color-grey-mid;
    color: $color-grey-light;

    &--active {
      background-color: $color-bg-primary;
      color: $color-white;
    }

    &--drop {
      background-color: $color-red-dark;
      color: $color-white;
    }
    text-align: center;
  }
}

.report__draft-warning,
.report__unpublish {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: calc(100% - 2rem);

  font-weight: bold;
  font-size: $font-size-large;
  padding: 0.5rem;

  margin: 0.5rem 1rem 0 1rem;

  &--basic {
    width: 100%;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
  }
}

.report__draft-warning {
  border: 1px solid $color-red-dark;
  background-color: $color-red-lightest;
  color: $color-red-dark;
}

.report__unpublish {
  text-decoration: underline;
  cursor: pointer;

  color: $color-bg-primary;
  &:hover {
    color: $color-bg-primary-highlight;
  }
}
