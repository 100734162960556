// Styling of the custom list React component

.list {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;

  width: 20rem;
  background-color: $color-white;

  border: 1px solid $color-grey-mid;
  border-radius: 0.5rem;

  padding: 0.5rem 0;
  margin: 0.5rem;

  .list__option {
    position: relative;
    padding: 2px 10px;
    width: 100%;

    transition: 0.2s all;

    cursor: pointer;

    &:hover {
      background-color: $color-grey-lighter;
    }

    &--selected {
      background-color: $color-bg-primary;
      color: $color-white;
    }

    .option__icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  .list__values-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    max-height: calc(100vh - 20rem);
    overflow-x: hidden;
    overflow-y: auto;
  }
}
