// Styling of textbox control

input {
  height: $control-outer-height;

  //margin: 0 0 0 0.5rem;
  // &:last-of-type {
  //   margin-right: 0.5rem;
  // }

  //padding: 0.3rem 1rem;
  border-width: 0;
}

.textbox {
  position: relative;
  .textbox__label {
    position: absolute;
    font-size: $font-size-x-small;
    top: 2px;
    left: 5px;
  }

  input {
    height: $text-box-height;
    padding: 0.3rem 1rem;
    border-width: 0;

    padding-bottom: 0;
  }
}
