.content__matters {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: $color-grey-lighter;

  width: 100%;
  height: 100%;

  .matters__toolbar {
    flex-shrink: 0;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    background-color: $color-grey-light;
    padding: 0.5rem 3rem;
    height: 4rem;

    .matters__paginator {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      margin: 0 2rem;

      .matters__page-action {
        display: flex;
        align-items: center;

        font-size: $font-size-x-large;
        margin: 0 2px;
        width: 2rem;

        background-color: $color-grey-mid;
        color: $color-grey-light;

        border-radius: 0.3rem;

        &--active {
          background-color: $color-bg-primary;
          color: $color-white;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .matters__page-number {
        margin: 0 1rem;
      }
    }

    .matters__compare-slider {
      margin-left: 5rem;
      width: 20rem;
    }
  }

  .matters__record {
    min-height: 1px; // Without this, the flow just breaks, and I don't know why

    width: 100%;
    overflow-x: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-start;

    background-color: $color-grey-lighter;

    .record__comparison {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;
      height: 100%;

      .record__matter-record {
        position: relative;
        display: flex;
        flex-flow: column nowrap;
        // justify-content: center;
        // align-items: center;

        width: 100%; // 70rem;
        //max-width: 95rem;
        height: calc(100% - 2rem);

        //background-color: $color-grey-lighter;

        border-radius: 1rem;

        margin: 1rem 0 0 1rem;

        &--pinned {
          border: 2px $color-yellow solid;
        }

        &:last-of-type {
          margin-right: 1rem;
        }

        .matter-record__actions-container {
          position: absolute;
          top: 0.5rem;
          right: 0.5rem;

          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-end;
          align-items: center;

          //width: 100%;

          background-color: $color-grey-mid;

          .matter-record__compare-actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            font-size: $font-size-large;
            padding: 0.3rem;

            background-color: $color-bg-primary;
            color: $color-grey-lightest;

            .matter-record__compare-action {
              display: flex;
              align-items: center;

              transition: 0.2s all;

              &:hover {
                cursor: pointer;
                color: $color-grey-dark;
              }

              .compare-action__pinned {
                color: $color-yellow;
              }
            }
          }
        }
      }
    }
  }
}
