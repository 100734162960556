.login {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  z-index: 999;

  background-color: $color-modal-mask;

  .login__popup {
    width: 25rem;
    background-color: $color-grey-light;

    border-radius: 1rem;

    color: $color-grey-dark;

    .login__header {
      padding: 1rem;
      background-color: $color-bg-primary;
      color: $color-white;
      font-size: $font-size-large;
      font-weight: bold;

      border-radius: 0.5rem 0.5rem 0 0;
    }

    .login__body {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
      padding-bottom: 1rem;

      .login__row {
        padding: 1rem;
      }

      input {
        font-weight: bold;
        border-radius: 0.5rem;
      }
    }
  }
}
