// Styling of buttons

button,
.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;

  //margin: .5rem;
  padding: 0.5rem 1rem;

  height: $control-outer-height;

  background-color: $color-bg-button;
  border: 0;
  //border-radius: 0.5rem;

  //font-weight: bold;
  //font-size: $font-size-large;
  color: $color-white;

  transition: 0.3s all;
  &:hover {
    background-color: $color-bg-primary;
  }

  &:active {
    background-color: $color-bg-button-highlight;
  }

  cursor: pointer;
}

button.disabled {
  cursor: auto;
  //border: 1px solid $color-grey-mid;
  background-color: $color-grey-mid;
}

.close-popup {
  background-color: $color-red-dark;
  width: 2rem;
  height: 2rem;

  padding: 3px;
  margin-right: 0.5rem;

  cursor: pointer;

  transition: 0.3s all;

  &:hover {
    background-color: $color-red;
  }

  &:active {
    background-color: $color-white;
  }

  &--absolute {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
}

button.cancel {
  color: $color-text-primary;
  background-color: $color-grey-light;
}

button:focus {
  outline: 2px solid $color-white;
}
