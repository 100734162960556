// Fundamental styling components

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-land) {
    font-size: 56.25%; // 1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    font-size: 50%; // 1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 75%; // 1rem = 12px, 12/16 = 75%
  }
}

body {
  box-sizing: border-box;
  user-select: none;
}

// Removes default focus borders on screen elements when selected
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

// General modifier for any elements that can be clicked
.clickable {
  &:hover {
    cursor: pointer;
  }
}

table {
  width: 100%;
}

// Scrollbars
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $color-grey-light $color-grey-mid;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: $color-grey-light;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: $color-grey-dark;
  border-radius: 20px;
  //border: 3px solid orange;
  &:hover {
    background-color: $color-grey-mid;
  }
}

// Prevents click messages on svgs from interfering with menu close events
svg:not(.highcharts-root) {
  pointer-events: none;
}

.validation-error,
.delete {
  color: $color-red-dark;
}

.test-div {
  flex-shrink: 0;
  background-color: yellow;
  width: 100%;
  height: 3rem;

  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: bold;
}

.link {
  color: $color-blue-darker;
  text-decoration: underline;
  font-weight: bold;

  &:hover {
    cursor: pointer;
    color: $color-blue-light;
  }
  &--center {
    text-align: center;
  }
  &--small {
    font-size: $font-size-small;
  }
}

.color-text-yellow {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $color-yellow;
}

.color-text-red {
  color: $color-red-dark;
  font-weight: bold;
}

.color-text-green {
  color: $color-green-dark;
  font-weight: bold;
}
