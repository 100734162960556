.radio-group {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

  label {
    margin-left: 0.5rem;
  }

  input {
    margin-left: 1rem;
  }
}

.radio-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
}

.button-radio-group {
  display: flex;
}

.button-radio-group input[type="radio"] {
  display: none; /* Hide the actual radio buttons */
}

.button-radio-group label {
  padding: 0.5rem 1rem; /* Adjust spacing as needed */
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem; /* Adjust spacing between buttons */
}

.button-radio-group label:hover {
  background-color: #f0f0f0; /* Add hover effect */
}

.button-radio-group input[type="radio"]:checked + label {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}
