// styles for the simple search popup
.simple-search__popup {
  position: absolute;
  //left: 0.5rem;
  top: 3.3rem;

  z-index: 90000;

  padding-bottom: 0.5rem;

  background-color: $color-grey-lighter;
  color: $color-grey-darker;

  @include popup-shadow;

  border-radius: 0 0 0.5rem 0.5rem;

  font-size: $font-size-medium;

  max-height: calc(100vh - 8rem);
  overflow-x: hidden;
  overflow-y: auto;

  transition: max-height 0.2s ease-in;

  &--hide {
    max-height: 0;
  }

  &--access-rights {
    position: fixed;
    top: auto;
    bottom: 1rem;
    //max-height: 30rem;
    left: 48.5rem;
    border-radius: 0.5rem;
  }

  // .simple-search__general-fields {
  //   max-height: 40rem;

  //   overflow-x: hidden;
  //   overflow-y: auto;

  //   margin-right: 1rem;
  //   margin-bottom: 1rem;
  // }

  .simple-search__popup-focus-container {
    &:focus {
      outline: 2px dotted $color-grey-dark;
    }
  }

  .simple-search__suggested-fields-container {
    position: relative;
    width: 20rem;
  }

  // .simple-search__suggested-fields-scroll {
  //   max-height: 30rem;

  //   overflow-x: visible;
  //   overflow-y: auto;
  // }

  .simple-search__suggested-field-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    padding: 0.2rem 0;

    .simple-search__fav-icon {
      position: absolute;

      left: 2px;
      top: 4px;

      color: $color-bg-primary;

      cursor: pointer;

      &:hover {
        color: $color-bg-primary-highlight;
      }

      &--favourite {
        color: $color-yellow;
      }

      &--favourite:hover {
        color: $color-yellow-dark;
      }
    }

    &:not(:hover) {
      .simple-search__fav-icon {
        display: none;
      }
    }
  }

  .simple-search__suggested-field {
    //width: 20rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    padding: 0 1.5rem;

    width: 100%;

    &:hover {
      cursor: pointer;
      background-color: $color-grey-light;
    }
    &:not(:hover) {
      .simple-search__ellipsis {
        display: none;
      }
    }

    &--selected {
      font-weight: bold;
    }

    &--matching {
      background-color: $color-grey-lightest;
      font-size: $font-size-medium;
    }

    &--selected,
    &--selected:hover {
      background-color: $color-grey-light;
    }
  }

  .simple-search__section-title-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0.5rem 1.5rem 0 0;

    .simple-search__section-title {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;

      font-style: italic;
      font-weight: bold;
      font-size: $font-size-medium-plus;
      color: $color-bg-primary-dark;

      &--match {
        color: $color-bg-primary-dark;
      }
    }

    .simple-search__arrow {
      display: flex;
      justify-content: flex-end;

      cursor: pointer;

      &:hover {
        color: $color-grey-mid-dark;
      }
    }

    &:not(:hover) {
      .simple-search__arrow {
        display: none;
      }
    }
  }
}
