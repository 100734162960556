.contact-editor__popup {
  min-width: 50rem;
  background-color: $color-grey-light;

  border-radius: 1rem;

  color: $color-grey-dark;

  padding: 0.5rem;

  .popup-header {
    margin-bottom: 0;

    border-radius: 0.5rem 0.5rem 0 0;
  }

  button,
  .button {
    margin-top: 0.5rem;
  }
}
