// Definition of variables used in other SASS files

// Raw colors

$color-white: hsl(0, 0%, 100%);
$color-grey-lightest: hsl(0, 0%, 95%);
$color-grey-lightest-alt: hsl(0, 0%, 90%);
$color-grey-lighter: hsl(0, 0%, 87%);
$color-grey-light: hsl(0, 0%, 80%);
$color-grey-mid: hsl(0, 0%, 65%);
$color-grey-mid-dark: hsl(0, 0%, 50%);
$color-grey-dark: hsl(0, 0%, 40%);
$color-grey-darker: hsl(0, 0%, 25%);
$color-grey-darker2: hsl(0, 0%, 18%);
$color-grey-darkest: hsl(0, 0%, 10%);
$color-black: hsl(0, 0%, 0%);
$color-black-transparent: rgba(0, 0, 0, 0.5);

$color-red-lightest: hsl(0, 100%, 90%);
$color-red-lighter: hsl(0, 100%, 80%);
$color-red-light: hsl(0, 100%, 70%);
$color-red: hsl(0, 100%, 50%);
$color-red-dark: hsl(0, 40%, 50%);
$color-red-darker: hsl(0, 40%, 40%);
$color-red-darkest: hsl(0, 40%, 30%);

$color-yellow-lightest: hsl(50, 100%, 90%);
$color-yellow-light: hsl(50, 100%, 60%);
$color-yellow: hsl(50, 100%, 50%);
$color-yellow-dark: hsl(50, 100%, 35%);
$color-yellow-darkest: hsl(50, 100%, 25%);

$color-green-lightest: hsl(150, 100%, 90%);
$color-green-lighter: hsl(150, 100%, 80%);
$color-green-light: hsl(150, 100%, 70%);
$color-green: hsl(150, 100%, 50%);
$color-green-mid-dark: hsl(150, 100%, 40%);
$color-green-dark: hsl(150, 100%, 30%);
$color-green-darkest: hsl(150, 100%, 10%);

$color-blue: hsl(218, 100%, 50%);
$color-blue-lightest: hsl(218, 25%, 90%);
$color-blue-lightest-alt: hsl(218, 25%, 85%);
$color-blue-lighter: hsl(218, 25%, 80%);
$color-blue-light: hsl(218, 25%, 58%);
$color-blue-mid: hsl(218, 25%, 46%);
$color-blue-mid-faded: hsl(218, 25%, 60%);
$color-blue-dark: hsl(218, 52%, 36%);
$color-blue-darker: hsl(218, 52%, 30%);
$color-blue-mid-transparent: hsla(218, 25%, 46%, 0.4);

$color-teal-lightest: hsl(162, 50%, 90%);
$color-teal-lighter: hsl(162, 50%, 80%);
$color-teal-light: hsl(162, 50%, 58%);
$color-teal-mid: hsl(162, 50%, 46%);
$color-teal-mid-faded: hsl(162, 50%, 46%);
$color-teal-dark: hsl(162, 82%, 36%);
$color-teal-darker: hsl(162, 82%, 30%);
$color-teal-mid-transparent: hsla(162, 50%, 46%, 0.4);

// Colors by function

$color-transparent: rgba(0, 0, 0, 0);

$color-modal-mask: $color-black-transparent;

$color-overlay-item-bg: hsla(0, 0%, 100%, 0.5);
$color-overlay-item: hsla(0, 0%, 50%, 0.5);

$color-text-primary: $color-grey-darker;
$color-text-primary-highlight: $color-grey-mid;
// $color-text-secondary: $color-teal-darker;
// $color-text-secondary-highlight: $color-teal-mid;
$color-text-secondary: $color-blue-darker;
$color-text-secondary-highlight: $color-blue-mid;

$color-bg-form: $color-grey-lightest;

// $color-bg-button: $color-teal-dark;
// $color-bg-button-highlight: $color-teal-mid;
$color-bg-button: $color-blue-mid;
$color-bg-button-highlight: $color-blue-light;

// $color-bg-primary: $color-teal-dark;
// $color-bg-primary-highlight: $color-teal-mid;
// $color-bg-primary-faded: $color-teal-mid-faded;
// $color-bg-primary-light: $color-teal-light;
// $color-bg-primary-lighter: $color-teal-lighter;
// $color-bg-primary-lightest: $color-teal-lightest;
// $color-bg-primary-dark: $color-teal-darker;
// $color-bg-mid-transparent: $color-teal-mid-transparent;

$color-bg-primary: $color-blue-mid;
$color-bg-primary-highlight: $color-blue-light;
$color-bg-primary-faded: $color-blue-mid-faded;
$color-bg-primary-light: $color-blue-light;
$color-bg-primary-lighter: $color-blue-lighter;
$color-bg-primary-lightest: $color-blue-lightest;
$color-bg-primary-lightest-alt: $color-blue-lightest-alt;
$color-bg-primary-dark: $color-blue-darker;
$color-bg-mid-transparent: $color-blue-mid-transparent;

// Sizes
$control-outer-height: 3rem;
$text-box-height: 3.5rem;

// Font-sizes
// $font-size-x-small: 1.1rem;
// $font-size-small: 1.2rem;
// $font-size-medium: 1.4rem;
// $font-size-large: 1.7rem;
// $font-size-x-large: 2rem;
$font-size-x-small: 0.95rem;
$font-size-small: 1.1rem;
$font-size-medium: 1.2rem;
$font-size-medium-plus: 1.3rem;
$font-size-large: 1.5rem;
$font-size-x-large: 1.8rem;
$font-size-xx-large: 2.5rem;
$font-size-xxx-large: 4rem;

// Tooltip
$tooltip-text-color: $color-white;
$tooltip-background-color: $color-grey-darker;
$tooltip-margin: 3px;
$tooltip-arrow-size: 6px;
