// Styling of popup header to be used in all popup windows and menus

.popup-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  //margin-bottom: 1rem;
  //border-bottom: 1px solid $color-grey-mid;
  background-color: $color-bg-primary;
  color: $color-white;

  .popup-header__title {
    // display: flex;
    // align-items: center;
    font-size: $font-size-medium;
    font-weight: bold;
  }

  .popup-header__close-x {
    color: $color-white;
    cursor: pointer;
    &:hover {
      color: $color-black;
    }
  }
}
