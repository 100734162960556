.record-universal-popup {
  @include popup-shadow;
  width: 90vw;
  height: 90vh;

  padding-top: 1rem;
  border-radius: 1rem;
  padding-bottom: 4rem;

  background-color: $color-grey-lighter;

  .record-universal-popup__close-icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;

    font-size: x-large;
    color: $color-grey-dark;
    margin-right: 1rem;

    &:hover {
      cursor: pointer;
      color: $color-grey-mid;
    }
  }

  // These are to override the default .select behaviours in the security system
  // !important is lazy, but otherwise I'd have to do the full hierarchy of record, panel and field to get the right specificity
  .select {
    padding-left: 0 !important;
    height: auto !important;
  }

  .field__display-value {
    .select {
      background-color: $color-grey-lighter !important;
    }
  }
}
