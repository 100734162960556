// Styles for common html controls and overrides of

// We hide the default HTML file upload box since we're replacing it with our own "custom label" (it's near impossible to style file inputs directly)
input[type="file"] {
  display: none;
}

ul {
}

select {
  border: none;
  background-color: $color-grey-lighter;
  color: $color-grey-darkest;
  //appearance: none;
  padding: 0 0.5rem;
}

.checkbox {
  display: flex;
  flex-flow: row;
  align-items: center;
  &--left {
    justify-content: flex-start;
  }
  &--center {
    justify-content: center;
  }
  &--right {
    justify-content: flex-end;
  }

  .checkbox__check {
    height: 1.2rem;
    width: 1.2rem;
  }
  .checkbox__label {
    margin-left: 0.5rem;

    &--disabled {
      color: $color-grey-mid;
    }
  }
}

button,
.button {
  border-radius: 0.5rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: $color-bg-primary;
  color: $color-white;
  height: 3rem;

  cursor: pointer;

  &:hover {
    background-color: $color-bg-primary-light;
  }
}

textarea {
  resize: vertical;
  border-radius: 0.3rem;
}
