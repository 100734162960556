// Styling of the custom Select React component

.select {
  position: relative;
  //height: $control-outer-height;

  //padding-top: 0.5rem;
  border-width: 0;
  border-radius: 0.5rem;

  width: 100%;

  &--country {
    margin: 2px 0;
  }

  &--disabled {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    align-items: center;
    min-height: 3rem;
  }

  .select__dropdown {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.5rem;
    //border-radius: 0.5rem;

    input {
      width: 100%;
    }
  }

  .select__popup {
    position: absolute;
    top: 100%;
    left: 5px;

    &--bottom-up {
      top: auto;
      bottom: 100%;
    }

    z-index: 9;

    border: 1px solid $color-grey-mid;

    background-color: $color-white;

    &--multi-valued {
      background-color: $color-grey-lighter;
    }

    min-width: 20rem;
    max-height: 20rem;
    overflow-x: hidden;
    overflow-y: auto;

    padding-bottom: 2rem;

    //position: relative;

    .select__expand-button {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
      font-size: $font-size-x-small;
      padding: 0.5rem;
      height: auto;
      border-radius: 3px;
      //color: $color-bg-primary;
    }
  }
}

.select__selected-multi-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  min-height: 3rem;

  .select__selected-multi-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    margin: 2px;
    padding: 2px 5px;
    border-radius: 0.3rem;

    font-size: $font-size-small;
    color: $color-white;
    background-color: $color-bg-primary;

    .select__selected-multi-item__close {
      display: flex;
      justify-content: center;
      align-items: center;

      padding-left: 2px;
      cursor: pointer;
    }
  }
}

.select__full-screen-popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: $color-grey-lighter;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  width: 80vw;
  //width: 100%;
  min-width: 40vw; /* Minimum width to ensure popup doesn’t shrink too much */
  overflow-x: auto;

  height: 80vh; /* to prevent overflow */
  overflow-y: hidden;
}

.popup-content {
  column-width: 200px; /* minimum column width */
  column-gap: 20px; /* space between columns */
  height: calc(80vh - 8rem);
  width: 100%;

  margin-bottom: 1rem;
  /* Prevent vertical scrolling within the content */
  //overflow-y: hidden;
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
}

// .popup-content {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjust minmax as necessary */
//   grid-auto-rows: auto;

//   /* Set width to handle content overflow and ensure horizontal scrolling */
//   width: 100%;
//   overflow-x: auto; /* Enable horizontal scrolling if content exceeds width */
// }

.select__popup,
.select__full-screen-popup {
  .select__popup-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1rem;

    &:hover {
      cursor: pointer;
      background-color: $color-grey-lightest;
      font-weight: bold;
    }
  }

  .select__option {
    font-size: $font-size-medium;
    //background-color: $color-white;
    padding: 0.5rem;
    &:hover {
      cursor: pointer;
      background-color: $color-grey-lightest;
    }
    &--active {
      color: $color-yellow-dark;
    }
  }

  .matching-chars {
    font-weight: bold;
    color: $color-bg-primary;
  }
}

.select__full-screen-popup {
  .popup-content {
    .select__option {
      padding: 0.2rem;
    }
  }
}
