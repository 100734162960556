.content__search-results {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 5.5rem auto 4rem;

  grid-template-areas: "results-header" "results-data-grid" "results-footer";

  height: calc(100% - 6px); //99%;  //calc(100vh - 6rem);

  &--embedded {
    display: flex;
    flex-flow: column nowrap;

    background-color: $color-white;

    height: calc(100vh - 22rem);
    //overflow-x: hidden;
    overflow-y: auto;

    // When using the embedded data grid we need to change some inner element css
    .search-results__data-grid {
      // thead {
      //   th {
      //     top: 0;
      //   }
      // }
      tbody {
        td {
          cursor: default;
        }
      }
    }
  }

  width: 100%;
}

/*************************
*** HEADER
*************************/
.search-results__header {
  // position: sticky;
  // top: 4rem;
  // background-color: $color-white;
  // z-index: 9;

  grid-area: results-header;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .header__left-section {
    display: flex;
    align-items: center;

    .header__back-chevron {
      display: flex;
      align-items: center;

      font-size: $font-size-x-large;
      margin-right: 1rem;

      &:hover {
        color: $color-grey-mid;
        cursor: pointer;
      }
    }

    .header__results-found {
      font-size: $font-size-large;
      font-weight: bold;
    }

    .header__number-selected {
      font-size: $font-size-small;
      &__inner:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    .header__abbreviated {
      margin-left: 2rem;
      font-size: $font-size-medium;
    }
  }

  .header__right-section {
    display: flex;
    align-items: center;
    .header__results-type-selector {
      display: flex;
      justify-content: center;

      margin: 0 2rem;
      .header__result-type-icon {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;

        padding: 1rem;

        font-size: $font-size-x-large;

        .header__result-type-icon-text {
          font-size: $font-size-x-small;
        }

        &:hover {
          cursor: pointer;
          color: $color-grey-mid;
        }

        border-bottom: 7px solid transparent;
        color: $color-grey-light;

        &--selected {
          border-bottom: 7px solid $color-bg-primary;
          color: $color-grey-dark;
        }
      }
    }
  }
}

.search-results__num-results {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;

  font-weight: bold;
}

/*************************
*** DATA GRID
*************************/
.search-results__data-grid {
  grid-area: results-data-grid;

  //height: calc(100vh - 14rem);
  //overflow-x: hidden;
  overflow-y: auto;

  background-color: $color-grey-mid;
  border-radius: 0.5rem;

  table {
    border-spacing: 1px;
    border-collapse: separate;
  }

  thead {
    background-color: $color-grey-light;

    th {
      position: sticky;
      top: 0;
      z-index: 1;

      padding: 0.5rem 2px;

      background-color: $color-grey-light;

      white-space: nowrap; /* Prevent text from wrapping */
      overflow: hidden; /* Hide overflowed text */
      text-overflow: ellipsis; /* Show ellipsis (...) if text overflows */

      &:first-of-type {
        border-radius: 0.5rem 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 0.5rem 0 0;
      }

      font-weight: bold;
      color: $color-grey-darkest;

      cursor: pointer;
      &:hover {
        color: $color-grey-dark;
        text-decoration: underline;
      }
    }
  }

  tr {
    &:hover {
      background-color: $color-bg-primary-lightest;
      cursor: pointer;
    }

    &:nth-child(even) {
      .data-grid__cell {
        background-color: $color-grey-lightest;
      }
    }
    &:nth-child(odd) {
      .data-grid__cell {
        background-color: $color-white;
      }
    }
    &.data-grid__row--duplicate {
      td.data-grid__cell {
        background-color: $color-red-lightest;
      }
    }
  }

  .data-grid__cell {
    font-size: $font-size-small;
    padding: 0.5rem;

    //overflow-wrap: break-word; // Doesn't work in breaking up very long strings without spaces
    word-break: break-word; // This works

    text-align: center;

    &--20 {
      width: 20%;
    }

    &--30 {
      width: 30%;
    }

    &--40 {
      width: 40%;
    }

    &--50 {
      width: 50%;
    }
    &--success {
      background-color: $color-green-light !important;
      color: $color-green-darkest !important;
    }
    &--failure {
      background-color: $color-red-light !important;
      color: $color-red-darkest !important;
    }
    &--in-progress {
      background-color: $color-yellow-light !important;
      color: $color-yellow-darkest !important;
    }
  }

  .add-connected-matter__can-add {
    color: $color-blue;
    &:hover {
      color: $color-blue-light;
    }
  }

  th.add-connected-matter,
  td.add-connected-matter {
    position: sticky;
    right: 0;
  }

  // .data-grid__cell-connected-matter-add {
  //   // display: flex;
  //   // flex-flow: row nowrap;
  //   // justify-content: flex-start;
  //   // align-items: center;
  //   // width: 25rem;
  // }
}

/*************************
*** FOOTER
*************************/
.search-results__footer {
  grid-area: results-footer;

  display: flex;
  justify-content: center;
  align-items: center;

  // position: sticky;
  // bottom: 0;

  background-color: $color-grey-lighter;
}

/***********************
  REPORT VIEWER
***********************/

.search-results__report-viewer {
  background-color: $color-grey-lighter;

  .report-viewer__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;

    .report-menu__report-list {
      height: calc(100vh - 16.5rem);
      overflow-y: auto;
    }

    .report-menu__section {
    }
  }
}

.report-modal {
  margin: 1rem;
  //padding: 1rem;

  background-color: $color-white;
  border-radius: 0.5rem;
  border: 1px solid $color-bg-primary;

  width: 100vw;
  height: calc(100vh - 2rem);
  overflow-y: auto;

  .report-modal__body {
    padding: 1rem;
  }
}

img.data-grid__img {
  max-width: 4rem;
  max-height: 4rem;
  margin-right: 0.5rem;
}
