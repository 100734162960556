.Tooltip-Wrapper {
  display: flex;
  //justify-content: center;
  align-items: center;
  position: relative;
}

.Tooltip-Tip {
  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
  padding: 6px;
  color: $tooltip-text-color;
  background: $tooltip-background-color;
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;
}

.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: $tooltip-arrow-size;
  margin-left: calc($tooltip-arrow-size * -1);
}

.Tooltip-Tip.top {
  top: calc($tooltip-margin * -1);
}
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: $tooltip-background-color;
}

.Tooltip-Tip.right {
  left: calc(100% + $tooltip-margin);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.Tooltip-Tip.right::before {
  left: calc($tooltip-arrow-size * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: $tooltip-background-color;
}

.Tooltip-Tip.bottom {
  bottom: calc($tooltip-margin * -1);
}
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: $tooltip-background-color;
}

.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + $tooltip-margin);
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.Tooltip-Tip.left::before {
  left: auto;
  right: calc($tooltip-arrow-size * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: $tooltip-background-color;
}

// MULTI-LINE TOOLTIP
.tooltip-multi-wrapper {
  display: flex;
  align-items: center;
  position: relative;

  height: 100%;

  .tooltip-multi {
    position: absolute;

    width: 30rem;
    z-index: 999;

    border-radius: 0.5rem;
    padding: 0.5rem;
    color: $color-grey-darker;
    background-color: $color-white;
    border: 1px solid $color-grey-mid;
    @include popup-shadow();

    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    &--top {
      bottom: 0;
    }

    &--bottom {
      top: 0;
    }
  }

  .Tooltip-Tip__Line {
    padding: 4px 0;
  }
}
