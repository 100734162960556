.project {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  margin-right: 1rem;
  padding: 0.5rem;

  //background-color: $color-grey-lighter;
  border-radius: 1rem;

  .project__row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    width: 100%;

    // margin-bottom: 0.5rem;
    // &:nth-last-of-type() {
    //   margin-bottom: 0;
    // }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }

    &--space-around {
      justify-content: space-around;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  .project__name {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;

    font-size: $font-size-large;
    font-weight: bold;

    .link {
      font-size: $font-size-small;
    }
  }

  .project__type {
    font-weight: bold;
  }

  .project__input,
  .project__select {
    background-color: $color-white;
    border-radius: 3px;
    padding: 0 1rem;
    width: 20rem;
  }
}

.project-body__home {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  //gap: 0.5rem;
  padding: 1rem;
  //border-radius: 0 0 0.5rem 0.5rem;

  background-color: $color-grey-lighter;
}

.project__popup {
  @include popup-shadow;
  width: 35rem;

  &--wide {
    width: fit-content;
    margin-left: 4rem;
    margin-right: 4rem;
  }

  background-color: $color-grey-light;
  border-radius: 0.5rem;

  .popup-header {
    border-radius: 0.5rem 0.5rem 0 0;
    padding: 1rem;
  }

  .project-popup__row {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .project-popup__button-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .project-popup__label {
    font-size: $font-size-medium;
    font-weight: bold;
    margin: 0.5rem;
  }

  .project-popup__button {
  }
}

.batch-create__projects-list-popup {
  right: 23rem;
  padding-bottom: 1rem;

  .projects-list-popup__draft-projects-list {
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    background-color: $color-grey-lightest;
    .projects-list-popup__draft-project {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.search__bar {
  background-color: $color-grey-lightest;
  border-radius: 0.5rem;
}

.project__filter-bar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .filter-bar__label {
    font-weight: bold;
  }
}

.project__popup,
.project__filter-bar {
  input,
  .select {
    background-color: $color-white;
    border-radius: 3px;
    padding: 0 1rem;
    width: 20rem;
  }
}

.search__bar,
.filter-bar__search {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  padding: 0.5rem;

  input {
    margin-left: 1rem;
    background-color: $color-white;
    border-radius: 3px;
    padding: 0 1rem;
    width: 30rem;
  }

  .search__icon {
    position: absolute;
    right: 1rem;
    top: 1.4rem;
    color: $color-grey-dark;
  }
}

.project__table {
  max-height: calc(100vh - 10rem); /* Set your desired maximum height */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Disable horizontal scrolling */

  .table__no-records {
    font-size: $font-size-large;
    font-weight: bold;
    padding: 1rem;
  }
}

.table__inner {
  margin: 0.5rem;
  padding: 0.5rem;
  background-color: $color-grey-light;
  border-radius: 0.5rem;

  /* Alternating row colors */
  tr:nth-child(odd) {
    background-color: $color-grey-lightest;
  }
  tr:nth-child(even) {
    background-color: $color-grey-lighter;
  }

  /* Sticky table header */
  th {
    background-color: $color-grey-mid;
    height: 3rem;
    padding: 0 1rem;
    position: sticky;
    top: 0;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: $color-grey-dark;
    }
  }

  td {
    text-align: center;
    height: 3rem;
    padding: 0 1rem;

    /* Additional styling for checkbox container */
    .checkbox-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
