.matter-record__header {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  width: 100%;

  .record-header__info {
    display: flex;
    flex-flow: row nowrap;
    //justify-content: flex-start;
    align-items: stretch;

    background-color: $color-grey-lightest;

    border-radius: 1rem 1rem 0 0;

    width: 100%;

    .record-header__logo {
      img {
        max-width: 10rem;
        max-height: 10rem;
      }
    }

    .record-header__text-section {
      display: flex;
      flex-flow: column nowrap;
      //justify-content: flex-start;
      //align-items: flex-start;

      flex-grow: 1;

      .record-header__title-bar {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-evenly;
        align-items: center;

        width: 100%;

        //background-color: $color-bg-primary-faded;
        color: $color-white;
        font-size: $font-size-x-large;

        .record-header__title-item {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: $color-bg-primary;

          flex-grow: 1;

          height: 100%;

          padding: 0.5rem;

          &:first-of-type {
            margin-right: 2px;
            //border-radius: 1rem 0 0 0;
          }

          &:last-of-type {
            border-radius: 0 1rem 0 0;
          }

          &--no-image {
            border-radius: 1rem 0 0 0;
          }
        }
      }

      .record-header__text-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
        align-items: stretch;
        //align-content: stretch;

        width: 100%;

        .record-header__item {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;

          margin: 1px 0 1px 1px;
          background-color: $color-bg-primary-lightest;
          padding: 0.5rem;

          font-size: $font-size-medium;

          flex-grow: 1;
          &:first-of-type {
            margin-left: 0;
          }

          .record-header__display-name {
            margin-left: 0.5rem;
            font-size: $font-size-small;
            color: $color-text-secondary;
          }

          .record-header__display-value {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;

            font-size: $font-size-medium;
            color: $color-text-primary;
          }
        }
      }
    }
  }

  .record-header__company {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;

    padding: 1rem;

    width: 100%;

    background-color: $color-bg-primary;
    color: $color-white;
    font-size: $font-size-x-large;

    border-radius: 0 1rem 0 0;

    .record-header__company-name {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;

      .record-header__matter-type-sub {
        font-size: $font-size-small;
      }

      .record-header__company-address {
        font-size: $font-size-small;
        font-style: italic;
      }
    }

    .record-header__references {
      font-size: $font-size-medium;
    }
  }

  .record-header__actions {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    background-color: $color-grey-lightest;

    border-top: $color-grey-light 1px solid;
    border-radius: 0 0 1rem 1rem;

    width: 100%;

    .record-header__page-selector-list {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-evenly;
      margin-left: 1rem;

      .record-header__page-selector {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: $font-size-small;
        font-weight: bold;
        text-transform: uppercase;

        padding: 0.5rem;

        border-bottom: transparent 2px solid;

        transition: all 0.2s;

        &:hover {
          color: $color-grey-mid;
          cursor: pointer;
        }
        &--selected {
          border-bottom: $color-grey-dark 2px solid;
        }
      }
    }

    .record-header__validation-summary {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;

      font-weight: bold;

      .validation-summary-item {
        margin: 0 1rem;
        &--success {
          color: $color-green-dark;
        }
        &--error {
          color: $color-red-dark;
        }
      }
    }

    .record-header__action-icon-list {
      display: flex;
      flex-flow: row;
      justify-content: space-evenly;
      align-items: center;

      width: 15rem;

      .record-header__action-icon {
        display: flex;
        align-items: center;

        font-size: $font-size-large;

        transition: all 0.2s;

        .bookmarked {
          color: $color-green-mid-dark;
        }

        &:hover {
          color: $color-grey-mid;
          cursor: pointer;
        }
      }
    }
  }
}
