.thumbnail-list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;

  padding: 1rem;

  background-color: $color-grey-lighter;
}

.thumbnail-list__interstice {
  height: 14rem;
  width: 5rem;

  z-index: 9;

  transition: 0.2s all;
  &--over {
    background-color: $color-bg-mid-transparent;
  }
}

.thumbnail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  margin-bottom: 0.5rem;
  margin-left: -4.5rem;

  padding: 1rem;

  &--header {
    margin: 0;
    padding: 0;
    width: 20rem;
  }

  .file-uploader {
    background-color: transparent;
  }

  .thumbnail__body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    position: relative;

    background-color: $color-white;
    padding: 1rem;

    width: 100%;

    border-radius: 0.5rem 0 0 0;

    cursor: grab;

    img {
      max-width: 16rem;
      max-height: 8rem;
      width: auto;
    }

    .body__overlay {
      //display: none;
      position: absolute;

      top: 0;
      right: 0;

      //width: 3rem;
      width: 100%;
      height: 100%;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: flex-start;

      &--header {
        align-items: center;
      }

      padding: 0.5rem;

      background-color: transparent;

      transition: 0.2s all;

      opacity: 0;

      &:hover {
        opacity: 1;
      }

      .overlay__items {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;

        .overlay__item {
          display: flex;
          // flex-flow: row nowrap;
          //justify-content: flex-start;
          // align-items: center;

          height: 2rem;
          width: 2rem;
          font-size: $font-size-x-large;

          background-color: $color-overlay-item-bg;
          color: $color-overlay-item;

          border-radius: 3px;

          transition: 0.2s all;
          &:hover {
            color: $color-yellow-light;
          }

          cursor: pointer;
        }
      }
    }
  }

  .thumbnail__description {
    width: 100%;
    input {
      width: 100%;
      text-align: center;
      padding: 0.5rem;
      background-color: $color-grey-lightest;
      font-size: $font-size-small;
      font-weight: bold;
    }
  }

  .thumbnail__header-pagination {
    width: 100%;
    padding-bottom: 0.5rem;

    background-color: $color-white;

    font-size: $font-size-small;
    text-align: center;
    font-weight: bold;
  }

  // .thumbnail modifiers for selection and selected mode
  &--selection-mode {
    border-radius: 1rem;
    margin: 0 0.5rem;
    transition: 0.2s all;
    &:hover {
      background-color: $color-bg-mid-transparent;
    }
    .thumbnail__body {
      cursor: pointer;
    }
    &:active {
      background-color: $color-bg-primary-lighter;
    }
  }

  &--selected,
  &--selected:hover {
    background-color: $color-bg-primary-highlight;
    .thumbnail__body {
      cursor: default;
    }
  }
}

.no-image-text {
  font-size: $font-size-x-small;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.content__image-store {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.popup__image-store {
  @include full-screen-popup;
}

.content__image-store,
.popup__image-store {
  .image-store__command-bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 4rem;

    padding: 0.5rem;

    background-color: $color-grey-light;

    .command-bar__item {
      display: flex;
      justify-content: center;
      font-weight: bold;
      width: 50%;
      margin-left: 0.5rem;
    }
  }
  .thumbnail-list {
    width: 100%;
  }

  .thumbnail {
    margin-left: 0;
    .thumbnail__body {
      border-radius: 0.5rem 0.5rem 0 0;
    }
    .thumbnail__description input {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
}
