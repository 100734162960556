// Styles for the configuration set of pages
.configuration {
  width: 100%;
  height: calc(100vh - 8rem);
  background-color: $color-grey-lighter;

  .configuration__title {
    font-size: $font-size-x-large;
    font-weight: bold;
    color: $color-text-primary;
    padding: 0.5rem;
  }

  .configuration__matter-type-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    width: 30rem;

    border-radius: 0.5rem;
    background-color: $color-grey-lightest;
    padding: 0.5rem;

    .matter-type-container__label {
      width: 12rem;
    }

    .matter-type-container__select {
      padding: 0 0.5rem;
      background-color: $color-white;
      border-radius: 0.5rem;
    }
  }

  .configuration__lookups {
    .lookups__title {
      font-size: $font-size-large;
      font-weight: bold;
      color: $color-text-primary;
      padding: 0.5rem;
    }

    .lookups__columns {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem;

      .lookup__column {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0.5rem;
        margin-right: 0.5rem;

        background-color: $color-grey-lightest;
        border-radius: 0.5rem;

        .column__title {
          font-size: $font-size-medium;
          font-weight: bold;
          color: $color-text-primary;
          padding: 0.5rem;
        }

        .column__button {
          margin-left: 0.5rem;
        }

        .edit__row {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          padding: 0.5rem;
        }

        .edit__label {
          width: 12rem;
          padding-right: 1rem;
        }

        .edit__value {
          min-width: 20rem;
          padding: 0.5rem;
          background-color: $color-white;

          &--control {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.list--lookup-values {
  max-height: calc(100vh - 27rem);
  overflow-y: auto;
}
